import { Config } from 'simple-react-notifications2/dist/NotificationContainer/NotificationContainer';

interface Notifier {
  configure: (cfg: Config) => void;
}

export const notifierConfig = (notifier: Notifier): void => {
  notifier.configure({
    autoClose: 5000,
    width: '275px',
    position: 'bottom-right',
    delay: 0,
    closeOnClick: true,
    pauseOnHover: true,
    onlyLast: false,
    rtl: false,
    newestOnTop: true,
    animation: {
      in: 'fadeIn',
      out: 'fadeOut',
      duration: 600,
    },
  });
};

export const notifierErrorMsg =
  'Something went wrong. Please try again. If the issue persists please contact us directly, by sending an email at TODO@mail.com';
