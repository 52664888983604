import {
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from '../assets/icons/custom-icons';
import React from 'react';
import './social-links.scss';

interface SocialProps {
  siteTitle: string;
  dark?: boolean;
}

const SocialLinks: React.FC<SocialProps> = ({
  siteTitle,
  dark,
}: SocialProps) => {
  const socialMediaData = [
    {
      name: 'Twitter',
      className: 'twitter',
      href: 'https://twitter.com/ElaineMays',
      icon: <TwitterIcon />,
    },
    {
      name: 'Instagram',
      className: 'instagram',
      href: 'https://www.instagram.com/elainekmays',
      icon: <InstagramIcon />,
    },
    {
      name: 'LinkedIn',
      className: 'linkedin',
      href: 'https://www.linkedin.com/in/elainemays/',
      icon: <LinkedInIcon />,
    },
  ];

  return (
    <div className={'flex'}>
      {socialMediaData.map((item) => (
        <a
          key={item.name}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={`${siteTitle}'s ${item.name}`}
          href={item.href}
          className={`p-2 flex items-center rounded fill-current transition-all duration-150 ease opacity-100 hover:bg-primary ${
            item.className
          } ${dark ? 'text-black' : 'text-white'}`}
        >
          {item.icon}
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
