import React from 'react';

import { RouteComponentProps } from '@reach/router';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const About: React.FC = () => {
  return (
    <div>
      <h2>A little bit about me</h2>
      <div className={'max-w-md'}>
        <p>Hello! I’m so glad that you’re here.</p>
        <p>
          I’m Elaine, and I love to give life to copy in a conversational style
          from a quality recording space with a timely turnaround. I do this so
          that you can feel confident in your project’s success and carry on
          with your work.
        </p>
        <p className={'m-0'}>
          I look forward to serving you and your organization.
        </p>
      </div>
    </div>
  );
};

const AboutPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <SEO title={'About'} />
      <About />
    </Layout>
  );
};

export default AboutPage;
