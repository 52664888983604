import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageId } from '../modules/routing';
import { ArrowLeftOutlined } from '@ant-design/icons';

const ThankYouPage: React.FC = () => {
  return (
    <Layout hideNavItems={true}>
      <SEO title={'Thank You'} />
      <div
        id={PageId.THANK_YOU}
        className="flex flex-col justify-center items-center mt-6"
      >
        <div style={{ maxWidth: '650px' }} className={'h-full'}>
          <div className={'flex flex-col items-start px-6'}>
            <h1>Thank you for getting in touch!</h1>
            <p className={'text-lg'}>
              I will look over your message and get back to you as soon as
              possible.
            </p>
            <a
              href={PageId.HOME}
              className={
                'link btn-lg text-primary block flex justify-center items-center gap-2 p-2 -ml-1'
              }
            >
              <ArrowLeftOutlined style={{ height: '20px' }} />
              Go Back
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
