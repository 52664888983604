import { PageId, Route } from '../modules/routing';
import { NAVBAR_HEIGHT } from '../models/navbar';

export function offsetScroll(pageId: PageId, location?: Location): void {
  if (location?.pathname === Route.THANK_YOU) {
    window.location.href = `${Route.HOME}#${pageId}`;
  }

  const element = document.getElementById(pageId);

  const yOffset = window.innerWidth <= 1000 ? -100 : -NAVBAR_HEIGHT; // navbar height

  if (element) {
    history.pushState({}, '', pageId);

    window.scrollTo({
      top: element.getBoundingClientRect().top + window.pageYOffset + yOffset,
      behavior: 'smooth',
    });
  }
}
