/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Route, routes } from '../modules/routing';
import './footer.scss';
import { HeartFilled, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import SocialLinks from './social-links';
import { offsetScroll } from '../tools/offsetScrollTo';
import { useLocation } from '@reach/router';

type FooterProps = {
  siteTitle: string;
  telephone: string;
};

const Footer: React.FC<FooterProps> = ({
  siteTitle,
  telephone,
}: FooterProps) => {
  const location = useLocation();

  return (
    <footer className="footer mt-auto">
      <div className={'footer-container'}>
        <div className={'footer__column footer__contact'}>
          <h2 className={'footer__h2 text-center'}>Contact</h2>
          <span className={'flex items-center'}>
            <MailOutlined className={'mr-2'} />
            <a
              className={'hover:text-blue-600'}
              href="mailto:contact@elainemays.com"
            >
              contact@elainemays.com
            </a>
          </span>
          <span className={'flex items-center'}>
            <PhoneOutlined className={'mr-2'} />
            <a className={'hover:text-blue-600'} href={'tel:' + telephone}>
              +1 (832) 577-4111
            </a>
          </span>
        </div>
        <div className="footer__column footer__social">
          <h2 className={'footer__h2'}>Connect with {siteTitle}</h2>
          <SocialLinks siteTitle={siteTitle} />

          <div className={'footer__copyright'}>
            © {new Date().getFullYear()}, Built with
            <HeartFilled className={'ml-2'} />
          </div>
        </div>
        <div className={'footer__column footer__sitemap'}>
          <h2 className={'footer__h2'}>Sitemap</h2>
          {routes
            .filter((route) =>
              [Route.THANK_YOU].every((path) => path !== route.path)
            )
            .map((route) => (
              <button
                onClick={() => offsetScroll(route.pageId, location)}
                key={route.path}
                className={
                  'sitemap__link transition-all rounded duration-150 px-2 ease opacity-100 hover:bg-primary'
                }
              >
                {route.title}
              </button>
            ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
