import React, { useEffect, useState } from 'react';
import { PageId, Route, routes } from '../modules/routing';
import { LocationProviderRenderFn, RouteComponentProps } from '@reach/router';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import './nav.scss';
import { offsetScroll } from '../tools/offsetScrollTo';
import { debounce } from 'throttle-debounce';

interface NavLinkProps extends RouteComponentProps {
  className?: string;
  pageId: PageId;
  children: React.ReactNode | LocationProviderRenderFn;
}

type NavProps = {
  hideNavItems?: boolean;
};

function scrollEventHandler(): void {
  const navContainer = document.querySelector('.Nav--Container');
  if (pageYOffset !== 0) {
    navContainer?.classList.add('Nav--Container--reduced-height');
  } else {
    navContainer?.classList.remove('Nav--Container--reduced-height');
  }
}

const Nav: React.FC<NavProps> = ({ hideNavItems }: NavProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleMenuToggle = () => setMenuIsOpen(!menuIsOpen);
  const handleLinkClick = (pageId: PageId) => {
    menuIsOpen && handleMenuToggle();
    offsetScroll(pageId, location);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(0, scrollEventHandler));
  });

  const logoLink = (
    <button
      onClick={() => handleLinkClick(PageId.HOME)}
      className={'focus:shadow-none flex flex-col items-start text-white'}
    >
      <span
        className={
          'text-bold min-w-max text-black text-xl font-extrabold transition-all leading-6 xl:text-3xl xl:leading-none'
        }
      >
        Elaine Mays
      </span>
      <small className={'text-base text-black'}>Voiceover Talent</small>
    </button>
  );

  const NavLink = ({ className, children, pageId, ...props }: NavLinkProps) => {
    return (
      <button
        className={`
        NavLink ${className}
      `}
        id={`navLink__${pageId}`}
        onClick={() => handleLinkClick(pageId)}
        {...props}
      >
        <span className={'NavLink--Span'}>{children}</span>
      </button>
    );
  };
  return (
    <header id="nav__top" className={'sticky top-0 bg-white z-50'}>
      <nav className={`Nav ${menuIsOpen ? 'Nav-active' : ''}`}>
        {hideNavItems ? (
          <div className="Nav--Container">{logoLink}</div>
        ) : (
          <div className="Nav--Container">
            {logoLink}
            <div className="flex z-50 flex-row justify-center items-center ml-40 text-white transition-all Nav--Links md:text-primary">
              {routes
                .filter((route) =>
                  [Route.THANK_YOU].every((path) => path !== route.path)
                )
                .map((route) => (
                  <NavLink
                    key={route.path}
                    pageId={route.pageId}
                    className={route?.cta ? 'cta' : ''}
                  >
                    {route.title}
                  </NavLink>
                ))}
            </div>

            <button
              aria-label={'toggle navigation menu'}
              className="flex z-50 p-2 mr-4 ml-auto rounded border-2 transition-all fill-current text-primary md:hidden border-primary"
              onClick={handleMenuToggle}
            >
              {menuIsOpen ? (
                <CloseOutlined className={'text-white'} />
              ) : (
                <MenuOutlined className={'text-primary'} />
              )}
            </button>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Nav;
