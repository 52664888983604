import { navigate, RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import notifier from 'simple-react-notifications2';
import emailjs, { init } from 'emailjs-com';
import { LoadingOutlined } from '@ant-design/icons';
import { notifierConfig, notifierErrorMsg } from '../modules/notifier-config';
import { ContactFormFields, Field, formRules } from '../modules/form-rules';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const Contact: React.FC = () => {
  return (
    <div className="px-4 lg:px-8 py-8 flex flex-col justify-center items-center rounded bg-light-gray">
      <div className="contact-page__wrapper-intro ">
        <div>
          <h2>Contact Me</h2>
          <p className={'text-base leading-7 mb-0'}>
            If you need more information, please let me know.
          </p>
          <p className={'text-base m-0 p-0 font-bold'}>
            I would love to talk with you.
          </p>
        </div>
      </div>
      <div
        className={'flex flex-col justify-center items-center w-full flex-wrap'}
      >
        <div className={'mt-5'}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export const ContactForm: React.FC = () => {
  init('user_OSsKV1mwgsoBLSCLTujN0');
  notifierConfig(notifier);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormFields>({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      phone: '',
    },
  });

  const [sendingEmail, setSendingEmail] = useState(false);

  const onSubmit = handleSubmit((data: ContactFormFields) => sendEmail(data));

  useEffect(() => {
    register(Field.NAME, formRules.name);
    register(Field.EMAIL, formRules.email);
    register(Field.MESSAGE, formRules.message);
  }, [register]);

  const sendEmail = (fields: ContactFormFields): void => {
    setSendingEmail(true);

    emailjs
      .send('service_cttvygt', 'template_hubad0o', fields)
      .finally(() => setSendingEmail(false))
      .then(
        () => {
          navigate('/thank-you');
        },
        (err) => {
          notifier.error(notifierErrorMsg);
          console.error(err);
        }
      );
  };

  return (
    <form
      className="w-full flex flex-col justify-center items-center mb-0"
      onSubmit={onSubmit}
    >
      <div className="flex flex-wrap max-w-md w-full">
        <div className="w-full">
          <label
            className="form__label form__label--first"
            htmlFor="first-name"
          >
            Name
          </label>
          <input
            className={`form__control form__input ${
              errors?.name && 'form__control--error'
            }`}
            id="first-name"
            type="text"
            {...register(Field.NAME)}
          />
          {errors?.name && <p className="form__error">{errors.name.message}</p>}
        </div>
        <div className="w-full">
          <label className="form__label" htmlFor="email">
            Email
          </label>
          <input
            className={`form__control form__input ${
              errors?.email && 'form__control--error'
            }`}
            id="email"
            type="email"
            {...register(Field.EMAIL)}
          />
          {errors?.email && (
            <p className="form__error">{errors.email.message}</p>
          )}
        </div>
        <div className="w-full">
          <label className="form__label" htmlFor="phone">
            Phone Number <small className="text-gray-700">(optional)</small>
          </label>
          <input
            className={`form__control form__input`}
            id="phone"
            type="tel"
            {...register(Field.PHONE)}
          />
        </div>
        <div className="w-full">
          <label className={`form__label`} htmlFor="message">
            Message
          </label>
          <textarea
            className={`form__control form__textarea ${
              errors?.message && 'form__control--error'
            }`}
            id="message"
            {...register(Field.MESSAGE)}
          />
          {errors?.message && (
            <p className="form__error">{errors.message.message}</p>
          )}
        </div>
      </div>
      <button
        disabled={sendingEmail}
        type="submit"
        className={`btn mt-5 flex items-center justify-center w-full ${
          sendingEmail && 'disabled pl-0'
        }`}
      >
        {sendingEmail && <LoadingOutlined className={'mr-2'} />}
        Send Message
      </button>
    </form>
  );
};

const ContactPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <SEO title={'Contact'} />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
