/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './layout.scss';
import Nav from './nav';
import Footer from './footer';

type LayoutProps = {
  children?: ReactNode;
  hideFooter?: boolean;
  hideNavItems?: boolean;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  hideNavItems,
  hideFooter,
}: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          telephone
        }
      }
    }
  `);

  const { title, telephone } = data.site.siteMetadata;

  return (
    <div className={'min-h-screen flex flex-col'}>
      <Nav hideNavItems={hideNavItems} />
      <div className={'h-full'}>
        <div className={'py-12'}>
          <main className={'flex w-full flex-col'}>{children}</main>
        </div>
      </div>
      {!hideFooter && <Footer siteTitle={title} telephone={telephone} />}
    </div>
  );
};

export default Layout;
