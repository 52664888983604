import { Router } from '@reach/router';
import React from 'react';
import ContactPage from '../pages/contact';
import ThankYouPage from '../pages/thank-you';
import AboutPage from '../pages/about';

export enum Route {
  ABOUT = '/about',
  CONTACT = '/contact',
  HOME = '/',
  THANK_YOU = '/thank-you',
}

export enum PageId {
  ABOUT = '_about',
  CONTACT = '_contact',
  HOME = '_home',
  THANK_YOU = '_thank-you',
  NOT_FOUND = '_not-found',
}

export const routing = (
  <Router>
    <AboutPage path={Route.ABOUT} />
    <ContactPage path={Route.CONTACT} />
    <ThankYouPage path={Route.THANK_YOU} />
  </Router>
);

export const routes = [
  {
    title: 'Home',
    path: Route.HOME,
    pageId: PageId.HOME,
  },
  {
    title: 'About',
    path: Route.ABOUT,
    pageId: PageId.ABOUT,
  },
  {
    title: 'Contact',
    path: Route.CONTACT,
    cta: true,
    pageId: PageId.CONTACT,
  },
];
