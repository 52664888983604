export enum Field {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  MESSAGE = 'message',
}

export interface ContactFormFields {
  [Field.NAME]: string;
  [Field.EMAIL]: string;
  [Field.PHONE]: string;
  [Field.MESSAGE]: string;
}

export const formRules = {
  [Field.NAME]: {
    required: {
      value: true,
      message: 'Please enter your name.',
    },
    maxLength: {
      value: 80,
      message: 'Please enter a shorter name.',
    },
  },
  [Field.EMAIL]: {
    required: {
      value: true,
      message: 'Please enter a valid email.',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email.',
    },
  },
  [Field.MESSAGE]: {
    required: {
      value: true,
      message: 'Please enter a message.',
    },
    minLength: {
      value: 5,
      message: 'Please enter a message.',
    },
  },
};
